import React from "react";
import Cookies from "js-cookie";
import TabTable from "../components/tab-table";
import { genImagePath } from "../utils/genImagePath";

const COMMON_COLUMNS = [
  {
    title: "图",
    dataIndex: "imgList",
    key: "imgList",
    width: 100,
    render: (value) => (
      <img
        src={genImagePath(value[0])}
        width="57"
        height="57"
        alt="icy商品图"
      />
    ),
  },
  {
    title: "商品名称",
    dataIndex: "name",
    key: "name",
  },
];

const TABS = [
  {
    value: "销售中",
    num: 2,
    columns: [
      ...COMMON_COLUMNS,
      {
        title: "发售日期",
        dataIndex: "saleDate",
        key: "saleDate",
        width: 150,
      },
      {
        title: "吊牌价",
        dataIndex: "hangTagPrice",
        key: "hangTagPrice",
      },
    ],
    filters: [{
      label: "发售日期",
      prop: "saleDate",
      type: "date",
    }, {
      label: "商品名称",
      prop: "name",
      defaultValue: "123",
    }],
  },
  {
    value: "待发售",
    num: 1,
    columns: [
      ...COMMON_COLUMNS,
      {
        title: "预计发售日期",
        dataIndex: "preSaleDate",
        key: "preSaleDate",
        width: 200,
      },
    ],
    filters: [{
      label: "发售日期",
      prop: "saleDate",
      type: "date",
      defaultValue: "2020-10-10",
    }, {
      label: "商品名称",
      prop: "name",
    }],
  },
  {
    value: "已取消",
    num: 3,
    columns: [
      ...COMMON_COLUMNS,
    ],
    filters: [],
  },
];

export default function Test() {
  return (
    <TabTable
      tabs={TABS}
      defaultTabVal={2}
      outerParams={{ userId: Cookies.get("userId") }}
      url="/designer/goodsList"
    />
  );
}